<template>
    <section class="portfolio" id="portfolio">
        <div class="container">
            
            <div class="row">
                <div class="col-md-12">
                    <h1 class="text-center com-barra com-barra-escura padding-y4"><strong>Portfólio</strong></h1>
                </div>
            </div>

            <div class="row padding-t1">
                <div class="col-md-10 offset-md-1">
                    <div class="row itens">
                        <div class="col-md-6 padding-y1" v-for="(item, item_i) in itens" :key="item_i">
                            <div class="row">
                                <div class="col-md-2">
                                    <img :src="item.icone" />
                                </div>
                                <div class="col-md-8">
                                    <h3>{{ item.titulo }}</h3>
                                    <p>{{ item.descricao }}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
            
    </section>
</template>

<script>
export default {
    
    data: () => {
        return {
            itens: [
                {
                    titulo: 'Infraestrutura',
                    descricao: 'Energia; Construção Civil.',
                    icone: '/assets/images/portfolio-icone-infra.png'
                },
                {
                    titulo: 'Condomínios',
                    descricao: 'Assessoria de Shopping Centers e de Condomínios Comerciais e Logísticos.',
                    icone: '/assets/images/portfolio-icone-condominios.png'
                },
                {
                    titulo: 'Imobiliário',
                    descricao: 'Regulação Fundiária; Desapropriações; Loteamentos e Desenvolvimento de Áreas; Operações Estruturadas; Locações.', 
                    icone: '/assets/images/portfolio-icone-imobiliario.png'
                },
                {
                    titulo: 'Saúde',
                    descricao: 'Assessoria de Clínicas, Hospitais e Sociedade Médicas.',
                    icone: '/assets/images/portfolio-icone-saude.png'
                },
                {
                    titulo: 'Mercado Financeiro e de Capitais',
                    descricao: 'Fundos; Títulos; Operações Financeiras; Direito Bancário.',
                    icone: '/assets/images/portfolio-icone-mercado.png'
                },
                {
                    titulo: 'Societário e Family Offices',
                    descricao: 'Estruturação e Acompanhamento de Negócios e Operações; Planejamento Societário; Planejamento Sucessório.',
                    icone: '/assets/images/portfolio-icone-societarios.png'
                }
            ]
        }
    }

}
</script>